import React, { useState, useEffect } from "react";
import CircularLoader from "./circularprogresbar";
 //const BASE_URL = "http://localhost:3001";
const BASE_URL = 'https://liveapi.lifti.io';
function App() {
  const [ssoPayload, setssoPayload] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [TransactionAmount, setTransactionAmount] = useState(0);
  const [userId, setuserId] = useState('');
  const [tokenName, settokenName] = useState(0);
  const [tokenValue, settokenValue] = useState(0);
  const [tokenPriceInUsdt, settokenPriceInUsdt] = useState(0);
  const [TransactionTypeId, setTransactionTypeId] = useState('3');
  const [token, settoken] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const _TransactionAmount = urlParams.get("TransactionAmount");
    const _userId = urlParams.get("userId");
    const _tokenName = urlParams.get("tokenName");
    const _tokenValue = urlParams.get("tokenValue");
    const _tokenPriceInUsdt = urlParams.get("tokenPriceInUsdt");
    const _token = urlParams.get("token");
    setTransactionAmount(_TransactionAmount);
    setuserId(_userId);
    settokenName(_tokenName);
    settokenValue(_tokenValue);
    settokenPriceInUsdt(_tokenPriceInUsdt);
    settoken(_token)
  }, []);

    // Second useEffect: Call getauthToken only when all params are available
    useEffect(() => {
      if (userId && tokenName && tokenValue && tokenPriceInUsdt && TransactionAmount) {
        getauthToken();
      }
    }, [userId, tokenName, tokenValue, tokenPriceInUsdt, TransactionAmount]); 

  const getauthToken = () => {
    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsLoading(true);
    fetch(
      `${BASE_URL}/alfah/pageRedirection?userId=${userId}&tokenName=${tokenName}&tokenValue=${tokenValue}&tokenPriceInUsdt=${tokenPriceInUsdt}&TransactionAmount=${TransactionAmount}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setssoPayload(result);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const handleSubmitPageRedirection = (e) => {
    e.preventDefault();
    setIsLoading(true);
    document.getElementById("PageRedirectionForm").submit();
  };

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        {!ssoPayload && (
          <div className="col-md-6 py-5">
            <h3>Payable Amount</h3>
            <input
              className="form-control"
              autoComplete="off"
              disabled
              type="text"
              value={TransactionAmount}
            />
            <br />
            <button
              onClick={() => getauthToken()}
              className="btn btn-danger w-100"
              id="handshake"
            >
              {isloading ? <CircularLoader /> : "Proceed"}
            </button>
          </div>
        )}
        {ssoPayload && (
          <div className="row justify-content-md-center py-5">
            <div className="col-md-6">
              <h3>Lifti Token Payment</h3>
              <form
                action="https://payments.bankalfalah.com/SSO/SSO/SSO"
                id="PageRedirectionForm"
                method="post"
                noValidate="novalidate"
                onSubmit={handleSubmitPageRedirection}
              >
                <input
                  id="AuthToken"
                  name="AuthToken"
                  type="hidden"
                  value={ssoPayload?.AuthToken}
                />
                <input
                  id="RequestHash"
                  name="RequestHash"
                  type="hidden"
                  value={ssoPayload?.RequestHash}
                />
                <input
                  id="ChannelId"
                  name="ChannelId"
                  type="hidden"
                  value={ssoPayload?.ChannelId}
                />
                <input
                  id="Currency"
                  name="Currency"
                  type="hidden"
                  value={ssoPayload?.Currency}
                />
                <input id="IsBIN" name="IsBIN" type="hidden" value="0" />
                <input
                  id="ReturnURL"
                  name="ReturnURL"
                  type="hidden"
                  value={ssoPayload?.ReturnURL}
                />
                <input
                  id="MerchantId"
                  name="MerchantId"
                  type="hidden"
                  value={ssoPayload?.MerchantId}
                />
                <input
                  id="StoreId"
                  name="StoreId"
                  type="hidden"
                  value={ssoPayload?.StoreId}
                />
                <input
                  id="MerchantHash"
                  name="MerchantHash"
                  type="hidden"
                  value={ssoPayload?.MerchantHash}
                />
                <input
                  id="MerchantUsername"
                  name="MerchantUsername"
                  type="hidden"
                  value={ssoPayload?.MerchantUsername}
                />
                <input
                  id="MerchantPassword"
                  name="MerchantPassword"
                  type="hidden"
                  value={ssoPayload?.MerchantPassword}
                />
                <select
                  className="form-select"
                  autoComplete="off"
                  hidden={true}
                  id="TransactionTypeId"
                  name="TransactionTypeId"
                  onChange={(e)=>setTransactionTypeId(e.target.value)}
                  value={ssoPayload?.MerchantPassword !=='' ? ssoPayload?.TransactionTypeId : TransactionTypeId}
                >
                  <option value="">Select Transaction Type</option>
                  <option value="1">Alfa Wallet</option>
                  <option value="2">Alfalah Bank Account</option>
                  <option value="3">Credit/Debit Card</option>
                </select>
                <br />
                <input
                  className="form-control"
                  autoComplete="off"
                  id="TransactionReferenceNumber"
                  name="TransactionReferenceNumber"
                  placeholder="Order ID"
                  type="text"
                  readOnly="true"
                  value={ssoPayload?.TransactionReferenceNumber}
                />
                <br />
                <input
                  className="form-control"
                  autoComplete="off"
                  id="TransactionAmount"
                  name="TransactionAmount"
                  placeholder="Transaction Amount"
                  type="text"
                  readOnly="true"
                  value={TransactionAmount}
                />
                <br />
                <button type="submit" className="btn btn-danger w-100" id="run">
                  {isloading ? <CircularLoader /> : "Proceed to Transaction"}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
